<template>
  <div class="main" :style="{width: screenSize.width, height: screenSize.height}">
    <div>
      <video autoplay muted loop
      :style="{width: '100%',  objectFit: 'fill'}">
        <source src="../../assets/content.mp4" type="video/mp4">
      </video>
    </div>
    <div class="video-mask"></div>
    <div class="main-box">
      <div class="header-box">
        <img src="../../assets/images/pc-logo.png" alt="">
      </div>
      <div class="content-box">
        <div class="content-title">
          <img src="../../assets/images/title-text-2.png"  alt=""/>
        </div>
        <div class="content-download">
          <span class="content-download-ewm">
            <img src="../../assets/images/ewm.png" alt="" />
          </span>
          <span class="content-download-btn">
            <button class="download-ios" @click="clickDownload('Mac')">Mac版下载</button>
            <button class="download-android" @click="clickDownload('Windows')">桌面版下载</button>
          </span>
        </div>
      </div>
      <div class="footer-box">
<!--        <div style="color: #FFFFFF;width: 50%;margin: 0 auto;">-->
<!--          <span style="font-size: 13px;">关于产品</span>-->
<!--          <div style="text-align: justify;margin-top: 10px;">-->
<!--            Infinite Box（无限盒子）,是启元天地旗下主推元宇宙产品,是一个平行于现实世界的沉浸式虚拟空间，每个人都能在这里有一个虚拟的身份，和现实中一样可以参与学习、社交，工作、看展、逛街和娱乐。-->
<!--          </div>-->
<!--        </div>-->
        <div style="color: #FFFFFF;width: 50%;margin: 10px auto">
<!--          <span style="font-size: 13px;">联系我们</span>-->
          <p style="display: flex;width: auto;justify-content: center;font-size: 16px;">
            <a href="/ysbh.html" style="text-decoration: none;color: #fff;">隐私协议</a>
            <a href="/yhxy.html" style="margin-left: 50px;text-decoration: none;color: #fff;">用户协议</a>
          </p>
          <div style="margin-top: 10px;">
            <p>北京启元天地网络信息科技有限公司 公司电话：010-53565500</p>
            <p>办公地址：北京市通州区商通大道5号院通州紫光科技园21号楼</p>
          </div>
          <div style="text-align: justify;margin-top: 10px;">
            北京启元天地网络信息科技有限公司，是北京全时天地在线网络信息股份有限公司全资子公司，成立于2021年11月29日，位于北京城市副中心通州紫光科技园。公司凭借实景影棚、数字影棚、专业级光、动捕设备、360度虚拍系统等多元化虚拟内容制作体系，致力于为企业级客户打造集合虚拟数字资产创造、商业化应用、资产管理、资产增值等可全场景虚拟现实空间无缝链接的商业服务生态闭环。
          </div>
        </div>

        <div class="footer-text">
          <b>京ICP备2021041162号-2</b>
          <b>
            <img style="width: 15px;height: 15px;cursor: pointer;" src="../../assets/images/police-logo.png" @click="clickPoliceLogo" />
            京公网安备 11011202003539号
          </b>
        </div>
      </div>
    </div>
  </div>
  <div class="no-download-tips"
    :style="{
      display: tipsVisible ? 'flex' : 'none'
    }"
  >
    <div class="no-download-tips-main">
      <span></span>
      <span>即将开启，敬请期待~</span>
    </div>
  </div>
<!--    <div class="chatgpt-link-box">-->
<!--        <span class="chatgpt-link-btn" @click="clickChatGPT">-->
<!--            <img src="../../assets/chatgpt-link-icon.png" alt="">-->
<!--        </span>-->
<!--    </div>-->
</template>

<script>
import axios from "axios";
const downloadApi = 'https://login1.himymeta.com/download'

export default {
  name: 'HomeApp',
  components: {},
  data(){
    return {
      screenSize: {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      },
      coverPosition: 0,
      position: {
        initX: 0,
        initY: 0,
        moveX: 0,
        moveY: 0,
        look: false,
        x: 0,
        y: 0,
      },
      tipsVisible: false,
    }
  },
  methods: {
    clickChatGPT(){
        window.location.href = '/?chatgpt'
    },
    clickPoliceLogo(){
      window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011202003539');
    },
    handleTips(){
      this.tipsVisible = true;
      setTimeout(() => {
        this.tipsVisible = false;
      },1500);
    },
    clickDownload(str){
      let self = this;
      const url = downloadApi + '?Mode=' + str
      axios.get(url).then(function(res){
        if(res.status === 200 && res.data && res.data.MsgCode === 100){
          const version = res.data.Data;
          let url = '';
          if(str === 'Windows'){
            url = version?.window?.Addr;
          }else{
            url = version?.mac?.Addr;
          }

          if(url === '' || url === undefined || url === null){
            self.handleTips();
            return;
          }
          window.location.href = url;
        }
      }).catch(function(error){
        console.log(error);
      })
    },
  },
}
</script>

<style>
*{
  padding: 0;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.chatgpt-link-box{
    display: block;
    position: fixed;
    bottom: 150px;
    right: 60px;
    z-index: 99999;
}

.chatgpt-link-btn{
    display: block;
    width: 50px;
    cursor: pointer;
}

.chatgpt-link-btn > img {
    width: 50px;
}

.no-download-tips{
  display: flex;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
}

.no-download-tips-main{
  display: block;
  width: 150px;
  height: 150px;
  background-color: rgba(0,0,0,.7);
  border-radius: 8px;
}

.no-download-tips-main >span:nth-child(1){
  display: block;
  width: 150px;
  height: 75px;
  background: url("../../assets/images/pc-tips.png") center no-repeat;
  background-size: 36px 36px;
  background-position-y: 30px;
}

.no-download-tips-main >span:nth-child(2){
  display: block;
  width: 100%;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  color: #fff;
  word-break: break-all;
}

.main{
  display: block;
  width: 100%;
  height: 100%;
  background: url("../../assets/images/bg.png") no-repeat;
  background-size: 100% 100%;
}

.earth-bg{
  display: block;
  width: 100%;
  height: 100%;
  background: url("../../assets/images/earch-bg.png") no-repeat;
  /*background-size: 100% 100%;*/
  transform: translate3d(0,0,0);
}

.cover-img{
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: url("../../assets/images/cover-img.png") repeat;
  background-size: 100% 100%;
  background-position-x: 0;
  top: 0;
  left: 0;
}

.main-box{
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.header-box{
  display: block;
  width: 11.33rem;
  height: 1.17rem;
  position: absolute;
  left: 3.33rem;
  top: 2.33rem;
}

.content-box{
  display: block;
  position: absolute;
  top: 30%;
  width: 100%;
}

.content-title{
  display: block;
  width: 100%;
}

.content-title > img{
  width: 40%;
}

.content-title-text{
  display: block;
  width: 100%;
  height: 1.83rem;
  font-size: 1.17rem;
  font-weight: 300;
  color: #FFFFFF;
  text-align: center;
  margin-top: 0.5rem;
}

.content-download{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 12.5rem;
}

.content-download-ewm{
  display: block;
  padding: 5px;
  background-color: #FFFFFF;
  width: calc(12.33rem - 10px);
  height: calc(12.33rem - 10px);
}

.content-download-ewm > img{
  width: 100%;
  height: 100%;
}

.content-download-btn{
  display: block;
  width: auto;
  margin-left: 3.33rem;
}

.download-ios{
  display: block;
  width: 17.92rem;
  height: 5rem;
  background: url("../../assets/images/bbs-mac-download.png") no-repeat;
  background-size: 100% 100%;
  outline: none;
  border: none;
  color: #FFFFFF;
  font-weight: 400;
  padding-left: 2.08rem;
  cursor: pointer;
  font-size: 1.58rem;
}

.download-android{
  display: block;
  width: 17.92rem;
  height: 5rem;
  background: url("../../assets/images/bbs-windows-download.png") no-repeat;
  background-size: 100% 100%;
  font-weight: 400;
  outline: none;
  border: none;
  color: #FFFFFF;
  margin-top: 2.5rem;
  padding-left: 2.08rem;
  cursor: pointer;
  font-size: 1.58rem;
}

.footer-box{
  display: block;
  position: absolute;
  width: 100%;
  bottom: 2.08rem;
}

.footer-text{
  display: block;
  width: auto;
  height: 1.17rem;
  line-height: 1.17rem;
  font-size: 1rem;
  font-weight: 400;
  color: #FFFFFF;
  /*opacity: 0.5;*/
}

.footer-text > b:nth-child(1){
  padding-right: 3.33rem;
}

.video-mask{
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 3;
  background-color: rgba(0,0,0,.4);
  top: 0;
  left: 0;
}

</style>
