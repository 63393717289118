import { createApp } from 'vue'
// import App from './App'
import Home from "@/pages/home/Home";
import Mobile from "@/pages/mobile/Mobile";
import ChatGPT from "@/pages/chatgpt/Chatgpt"
import ChatGPTMobile from "@/pages/chatgpt/Chatgpt_mobile"
import Doc  from "@/pages/doc/doc"

const query = window.location.search || window.location.hash
if (query.indexOf('chatgpt') !== -1) {
    if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
        createApp(ChatGPTMobile).mount('#app');
    } else {
        createApp(ChatGPT).mount('#app');
    }
} else if (query.indexOf('developer') !== -1) {
    createApp(Doc).mount('#app')
}else {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        createApp(Mobile).mount('#app');
    } else if (/(Android)/i.test(navigator.userAgent)) {
        createApp(Mobile).mount('#app');
    } else {
        createApp(Home).mount('#app');
    }
    // createApp(App).mount('#app');
}



