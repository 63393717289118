<template>
    <div class="doc-box">
        <div class="doc-header">
            <div class="doc-header-box">
                <div class="logo">
                    <img src="../../assets/tdzx-logo.png">
                    <span>天地在线</span>
                </div>
                <div class="tags">
                    <span><a href="https://qy.himymeta.com/">产品</a></span>
                    <span><a href="https://qy.himymeta.com/doc">支持</a></span>
                </div>
            </div>
        </div>
        <div class="doc-slide">
            <swiper
                :slidesPerView="'auto'"
                :navigation="false"
                :pagination="{
                    clickable: true,
                }"
                :autoplay="{
                    delay: 3000,
                }"
                :modules="modules"
                class="mySwiper"
                style="height: 100%"
            >
                <swiper-slide><img class="doc-banner-img" :src=imgConf.DocBanner1  alt=""/></swiper-slide>
                <swiper-slide><img class="doc-banner-img" :src=imgConf.DocBanner2  alt=""/></swiper-slide>
                <swiper-slide><img class="doc-banner-img" :src=imgConf.DocBanner2  alt=""/></swiper-slide>
            </swiper>
        </div>
        <div class="doc-door">
            <div class="doc-door-list">
                <div class="doc-door-item" @click="jumpDoc">
                    <span><a href="https://qy.himymeta.com/doc">ChatGPT 服务</a></span>
                </div>
                <div class="doc-door-item">
                    <span>数据中心</span>
                </div>
                <div class="doc-door-item">
                    <span>天地在线OA</span>
                </div>
                <div class="doc-door-item doc-no-open">
                    <span>(敬请期待)</span>
                </div>
            </div>
        </div>
        <div class="doc-footer">
            <div>
                <div class="doc-footer-link">
                    <a href="http://himymeta.com/computer#2">数字资产</a> |
                    <a href="https://qy.himymeta.com/">无限盒子</a> |
                    <a href="http://himymeta.com/computer#6">无限数藏</a> |
                    <a href="http://himymeta.com/computer/message/introduce">关于我们</a> |
                    <a href="http://himymeta.com/computer/message/contact">联系我们</a>
                </div>
                <div class="doc-footer-icp">
                    <a href="https://beian.miit.gov.cn/">京ICP备2021041162号-2</a> |
                    <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011202003539" class="doc-footer-icp-info"><img src="../../assets/images/police-logo.png" />京公网安备 11011202003539号</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper'
import { Pagination } from 'swiper'
import { Autoplay } from 'swiper'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import DocBanner1 from '@/assets/doc-banner-1.png'
import DocBanner2 from '@/assets/doc-banner-2.jpg'
import DocBanner3 from '@/assets/doc-banner-3.jpg'

const imgConf = {
    DocBanner1: DocBanner1,
    DocBanner2: DocBanner2,
    DocBanner3: DocBanner3,
}

export default {
    name: "docApp",
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Navigation, Pagination, Autoplay],
            imgConf: imgConf,
        };
    },
    methods:{
        jumpDoc(){
            window.location.href = 'https://qy.himymeta.com/doc'
        }
    },
}
</script>

<style scoped>
.doc-box{
    display: block;
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 100%;
    background: #F5F5F7;
}
.doc-header{
    display: block;
    width: 100%;
    height: 60px;
    background: #F5F5F7;
    box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.2);
    position: absolute;
    z-index: 10;
}
.doc-header-box{
    display: flex;
    width: auto;
    padding: 0 40px;
    height: 60px;
}
.logo{
    display: flex;
    align-items: center;
}
.logo > img{
    width: 40px;
    height: 40px;
}
.logo > span {
    font-size: 18px;
    font-weight: bold;
    margin-left: 5px;
}
.tags{
    display: flex;
    margin-left: 150px;
    width: auto;
    height: 60px;
    align-items: center;
}
.tags > span{
    display: inline-block;
    width: auto;
    margin-left: 44px;
    height: 58px;
    line-height: 58px;
    cursor: pointer;
    font-size: 16px;
    font-family: HarmonyOS Sans SC;
    font-weight: 300;
    color: #181818;
}
.doc-slide{
    display: block;
    width: 100%;
    height: 520px;
    position: absolute;
    top: 60px;
}
.doc-banner-img{
    width: 100%;
    height: 100%;
}
.doc-door{
    display: block;
    width: 100%;
    height: 300px;
    background: #F5F5F7;
    position: absolute;
    top: calc(60px + 520px);
}
.doc-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    position: absolute;
    bottom: 0;
}
.doc-footer-link{
    font-size: 15px;
}
.doc-footer-link > a {
    margin: 0 5px;
}
.doc-footer-icp{
    font-size: 14px;
    margin-top: 5px;
    display: flex;
    align-items: center;
}
.doc-footer-icp-info > img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}
.doc-footer-icp > a{
    margin: 0 5px;
    display: flex;
    height: 20px;
    line-height: 20px;
    align-items: center;
}
a{
    text-decoration: unset;
    cursor: pointer;
}
a:visited{
    color: #181818;
}
.doc-door-list{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding-top: 40px;
}
.doc-door-item{
    display: flex;
    width: 320px;
    height: 276px;
    margin: 0 8px;
    align-items: center;
    cursor: pointer;
}

.doc-door-item > span{
    display: block;
    height: 20px;
    line-height: 20px;
    margin-left: 30px;
    font-size: 16px;
    font-family: HarmonyOS Sans SC;
    font-weight: 400;
    color: #181818;
}

.doc-door-item > span > a{
    display: block;
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    font-family: HarmonyOS Sans SC;
    font-weight: 400;
    color: #181818;
}

.doc-door-list > .doc-door-item:nth-child(1){
    background: url("../../assets/doc-door-1.png") no-repeat;
    background-size: 100% 100%;
}
.doc-door-list > .doc-door-item:nth-child(2){
    background: url("../../assets/doc-door-2.png") no-repeat;
    background-size: 100% 100%;
}
.doc-door-list > .doc-door-item:nth-child(3){
    background: url("../../assets/doc-door-3.png") no-repeat;
    background-size: 100% 100%;
}
.doc-door-list > .doc-door-item:nth-child(4){
    background: url("../../assets/doc-door-4.png") no-repeat;
    background-size: 100% 100%;
}

::v-deep .swiper-pagination-bullet{
    width: 30px;
    height: 2px;
    border-radius: unset;
}
::v-deep .swiper-pagination-bullet-active{
    background-color: #000000;
    opacity: 0.8;
}
.doc-no-open > span{
    color: #dadada;
}
</style>
