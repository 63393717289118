<template>
  <div class="list-item" :style="{height: '100vh',overflow: 'hidden'}">
    <div class="list-summary">
      <div class="list-summary-title">{{data.title}}</div>
      <img class="list-summary-line" src="../../../assets/images/line.png" alt="" />
      <div class="list-summary-text">{{data.text}}</div>
    </div>
    <img class="list-img" style="height: 100vh" :src='data.imgUrl' alt="" />
  </div>
</template>

<script>
export default {
  name: "MainListItem",
  props: {
    title: String,
    text: String,
    imgUrl: String,
  },
  data(){
    return {
      data: {
        title: this.title,
        text: this.text,
        imgUrl: this.imgUrl,
      },
      screenHeight: document.documentElement.clientHeight,
    }
  }
}
</script>

<style scoped>
  .list-item{
    display: block;
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .list-summary{
    display: block;
    position: absolute;
    width: auto;
    margin: 0 3.5rem;
    top: 9.58rem;
    z-index: 5;
  }

  .list-summary-title{
    display: block;
    width: 100%;
    line-height: 3.17rem;
    font-size: 2.67rem;
    font-weight: 500;
    color: #FFFFFF;
    text-shadow: 0 0 0.25rem #00051E;
    text-align: justify;
  }

  .list-summary-line{
    width: 100%;
    height: 0.25rem;
  }

  .list-summary-text{
    display: block;
    width: 100%;
    line-height: 1.83rem;
    font-size: 1.25rem;
    font-weight: 400;
    color: #FFFFFF;
    text-shadow: 0 0 0.25rem #00051E;
    text-align: justify;
    opacity: .8;
  }

  .list-img{
    width: 100%;
    /*height: 100%;*/
    position: absolute;
    top: 0;
    left: 0;
  }
</style>
