<template>
  <div class="header-info">
    <img class="header-logo" src="../../assets/images/logo.png" alt="logo" />
    <div class="header-name">
      <span>无限盒子</span>
      <span>Infinite Box</span>
    </div>
  </div>
  <div class="list-box"
       :style="{
          height: '100vh',
          webkitTransform: 'translateY('+positionY+'px)',
          transform: 'translateY('+positionY+'px)',
          webkitTransitionDuration: '500ms',
          transitionDuration: '500ms',
        }"
  >
    <div class="page-cover" :style="{top: coverPositionY+'px'}"></div>
    <MainListItem
      v-for="(item, idx) in list"
      v-bind:key="idx"
      :title="item.title"
      :text="item.text"
      :imgUrl="item.imgUrl"
    />
  </div>
  <div class="download-btn-box">
    <button class="download-btn" v-on:click="download">立即下载</button>
<!--    <a class="download-btn" href="unity://com.DefaultCompany.XinstallTest" >立即下载</a>-->
  </div>
  <div class="more-arrow"
       :style="{display: this.offsetHeight === 0 ? 'block' : this.positionY === 0 - this.offsetHeight * 2 ? 'none' : 'block'}"
  >
    <b></b>
    <b></b>
    <b></b>
  </div>
  <div @click="hideIosTips" class="ios-tips" :style="{display: iosTips ? 'flex' : 'none'}">
    <img src="../../assets/images/ios-tips.jpg" alt="" />
  </div>

    <div class="update-tips-box" v-if="update === true">
        <div class="update-tips">
            <span class="update-tips-text">检测到新版本，请更新版本继续使用</span>
            <span class="update-tips-btn" v-on:click="download">点击下载</span>
        </div>
    </div>

<!--    <div class="chatgpt-link-box">-->
<!--        <span class="chatgpt-link-btn" @click="clickChatGPT">-->
<!--            <img src="../../assets/chatgpt-link-icon.png" alt="">-->
<!--        </span>-->
<!--    </div>-->
</template>

<script>
import axios from 'axios'

import MainListItem from "@/pages/mobile/components/MainListItem"
import img1 from '@/assets/images/1.gif'
import img2 from '@/assets/images/2.gif'
import img3 from '@/assets/images/3.gif'
import padImg1 from '@/assets/images/pad-1.gif'
import padImg2 from '@/assets/images/pad-2.gif'
import padImg3 from '@/assets/images/pad-3.gif'

const downloadApi = 'https://login1.himymeta.com/download'

const imgConfig = {
  img1: img1,
  img2: img2,
  img3: img3,
};

const platform = (/(iPhone|iPad|iPod|iOS|Mac)/i.test(navigator.userAgent)) ? 'IOS' : 'Android'

if(/(iPhone|iPad|iPod|iOS|Mac)/i.test(navigator.userAgent)){
    if(window.screen.width > 428){
      imgConfig.img1 = padImg1;
      imgConfig.img2 = padImg2;
      imgConfig.img3 = padImg3;
    }
}

export default {
  name: 'MobileApp',
  components: {
    MainListItem,
  },
  data() {
    return {
      list: [
        {
          title: '无限盒子-元宇宙的邀请帖，这个世界需要你的存在',
          text: '各具主题风格的24个地域，335个彼此连通的虚拟街区，12条覆盖全境的轨道交通，800平方公里的超大虚拟空间，以前所未有的规模迎接全球用户，共同创建元宇宙世界',
          imgUrl: imgConfig.img1,
        },
        {
          title: '秀出自我风采，创造美好未来',
          text: '每个人都是建设者，每个人都是体验者，在元宇宙的世界里，请大家尽情展现风采，为建设者干杯，致敬每一位不停共建的建设者',
          imgUrl: imgConfig.img2,
        },
        {
          title: '与现实世界平行存在的宇宙空间',
          text: '与现实交错，与科技融合，未知的世界与全新的宇宙，打破时空的壁垒，期待与你在另一个空间相遇',
          imgUrl: imgConfig.img3,
        }
      ],
      isWechat: false,
      iosTips: false,
      startY: 0,
      sleep: false,
      screenHeight: document.documentElement.clientHeight,
      offsetHeight: 0,
      positionY: 0,
      coverPositionY: 0,
      update: null,
    };
  },
    created() {
      // if (platform  === 'Android') {
      //     const s = document.createElement('script');
      //     s.type = 'text/javascript';
      //     s.charSet = 'UTF-8'
      //     s.src = 'https://xinstall.top/xinstall/banner.js?xid=h179258lqr';
      //     document.body.appendChild(s);
      // }

        let name = 'source'
        let reg = `(^|&)${name}=([^&]*)(&|$)`
        let r = window.location.search.substr(1).match(reg);
        this.update =  (r != null && unescape(r[2]) === 'client')
    },
    mounted() {
    window.addEventListener('touchstart', this.scrollPageStart, true)
    window.addEventListener('touchmove',this.scrollPage, true);

    document.body.addEventListener('touchmove', function (e) {
      e.preventDefault();
    }, { passive: false });

    window.addEventListener('touchmove', function (e) {
      e.preventDefault();
    }, { passive: false });

    if(/micromessenger/i.test(window.navigator.userAgent.toLowerCase())){
      this.isWechat = true;
    }

    window.addEventListener("orientationchange", function() {
      let num = window.orientation;
      if(num === 0 || num === 180){
        window.location.reload();
      }
    }, false);
  },
  methods: {
    clickChatGPT(){
      window.location.href = '/?chatgpt'
    },
    scrollPageStart(event){
      this.startY = event.changedTouches[0].pageY;
    },
    scrollPage(event){
      let clickPageY = this.startY;
      let currentPageY = event.changedTouches[0].pageY;

      // 页面下拉
      if(clickPageY + 40 < currentPageY){
        if(this.positionY === 0){
          return;
        }

        if(this.sleep){
          return;
        }

        this.sleep = true;

        this.offsetHeight = document.documentElement.offsetHeight;
        this.positionY = this.positionY + document.documentElement.offsetHeight;
        this.coverPositionY = this.coverPositionY - document.documentElement.offsetHeight;

        setTimeout(() => {
          this.sleep = false;
        },500);
      }

        // 页面上拉
      if(clickPageY > currentPageY + 40){
        if(this.positionY === 0 - (document.documentElement.offsetHeight * 2)){
          return;
        }

        if(this.sleep){
          return;
        }

        this.sleep = true;
        this.offsetHeight = document.documentElement.offsetHeight;
        this.positionY = this.positionY - document.documentElement.offsetHeight;
        this.coverPositionY = this.coverPositionY + document.documentElement.offsetHeight;
        setTimeout(() => {
          this.sleep = false;
        },500);
      }

    },
    hideIosTips(){
      this.iosTips = !this.iosTips;
    },
    download(){
      if(this.isWechat){
        this.iosTips = true;
        return;
      }

      // let res = downloadData;
      //
      // if(res.status === 200 && res.data.MsgCode === 100){
      //   const version = res.data.Data;
      //
      //   if(/(iPhone|iPad|iPod|iOS|Mac)/i.test(navigator.userAgent)){
      //     if((/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent))){
      //       window.location.href = version.ios.Addr;
      //     }else{
      //       window.open(version.ios.Addr);
      //     }
      //   }else{
      //     window.location.href = version.android.Addr;
      //   }
      // }

      const url = downloadApi + '?Mode=' + platform
      axios.get(url).then(function(res){
        if(res.status === 200 && res.data && res.data.MsgCode === 100){
          const version = res.data.Data;

          if(platform === 'IOS') {
              // alert('infinitebox://com.workqy.infinitebox')
              // window.location.href = 'infinitebox://com.workqy.infinitebox';
              let a = document.createElement('a');
              a.setAttribute('href', version.ios.Addr);
              a.click();


              // if((/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent))){
              //     window.location.href = version.ios.Addr;
              // }else{
              //     if(window.open(version.ios.Addr) === null || typeof(window.open(version.ios.Addr) === undefined)) {
              //         window.location.href = version.ios.Addr;
              //     } else {
              //         window.open(version.ios.Addr)
              //     }
              // }
          }else{
              window.location.href = 'infinitebox://com.workqy.infinitebox';
              setTimeout(() => {
                  window.location.href = version.android.Addr;
              }, 5000)
              // alert('infinitebox://com.workqy.infinitebox')
              // window.location.href = 'infinitebox://com.workqy.infinitebox';
          }
        }
      }).catch(function(error){
        console.log(error);
      })
    },
  }
}
</script>

<style>
*{
  padding: 0;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
  width: 100%;
  /*height: calc(3 * 120vh);*/
  overflow: hidden;
}

.chatgpt-link-box{
    display: block;
    position: fixed;
    bottom: 180px;
    right: 15px;
    z-index: 99999;
}

.chatgpt-link-btn{
    display: block;
    width: 40px;
    cursor: pointer;
}

.chatgpt-link-btn > img {
    width: 40px;
}

.update-tips-box{
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 4.17rem;
    background-color: rgba(0,0,0,0.6);
    z-index: 9;
    justify-content: center;
    align-items: center;
}

.update-tips{
    display: block;
    width: 100%;
    margin: -50px 20px 0;
    background-color: rgb(255, 255, 255);
    height: 180px;
    border-radius: 25px;
    position: relative;
    overflow: hidden;
    justify-content: center;
}

.update-tips-text{
    display: block;
    position:absolute;
    top: 30px;
    left: 0;
    right: 0;
    font-size: 15px;
    font-weight: bold;
}

.update-tips-btn{
    display: block;
    width: 80%;
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0px;
    height: 45px;
    line-height: 45px;
    background-color: #ffeb3b;
    margin: 0 auto;
    border-radius: 10px;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
}

.ios-tips{
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  top: 0;
  background-color: #323232;
}

.ios-tips > img{
  width: 340px;
  height: 432px;
  position: absolute;
  right: 0;
}

.page-cover{
  display: block;
  width: 100%;
  height: 100%;
  background: url("../../assets/images/index-cover.png");
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.header-info{
  display: flex;
  justify-content: left;
  align-items: center;
  height: 4.17rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0,0,0,.8);
  z-index: 10;
}

.header-logo{
  width: 2.5rem;
  height: 2.17rem;
  margin-left: 1.08rem;
}

.header-name{
  display: block;
  margin-left: 0.58rem;
  margin-top: 0.17rem;
}

.header-name > span:nth-child(1){
  display: block;
  width: 100%;
  height: 1.25rem;
  line-height: 1.25rem;
  font-size: 1.08rem;
  font-weight: 400;
  color: #FFFFFF;
  text-align: left;
}

.header-name > span:nth-child(2){
  display: block;
  width: 10.58rem;
  height: 1.17rem;
  line-height: 1.17rem;
  font-size: 1rem;
  font-weight: 400;
  color: #8C8C8C;
  text-align: left;
}

.download-btn-box{
  display: block;
  position: fixed;
  height: 6rem;
  bottom: 8rem;
  width: calc(100% - 3.5rem * 2);
  margin: 0 3.5rem;
  z-index: 5;
}

@media screen and (max-width:600px) {
  .download-btn{
    display: block;
    width: 100%;
    height: 3.75rem;
    line-height: 3.75rem;
    background: url("../../assets/images/download-icon-2.png") center no-repeat;
    background-size: 100% 100%;
    color: #fff;
    outline: none;
    border: none;
    position: absolute;
    bottom: 0;
    font-size: 1.25rem;
    text-shadow: 0 0 0.25rem #00051E;
    font-weight: 500;
  }
}

@media screen and (min-width:600px) {
  .download-btn{
    display: block;
    width: 100%;
    height: 3.75rem;
    line-height: 3.75rem;
    background: url("../../assets/images/download-icon-2.png") center no-repeat;
    background-size: auto 100%;
    color: #fff;
    outline: none;
    border: none;
    font-size: 1.25rem;
    text-shadow: 0 0 0.25rem #00051E;
    font-weight: 500;
    position: absolute;
    bottom: 0;
  }
}

.more-arrow{
  display: block;
  width: 100%;
  position: fixed;
  bottom: 1.67rem;
  z-index: 5;
}

.more-arrow > b{
  display: block;
  width: 100%;
  height: 0.67rem;
}

.more-arrow > b:nth-child(1){
  background: url("../../assets/images/more-icon-1.png") center no-repeat;
  background-size: 1.33rem 100%;
  -webkit-animation: arrow-data 1.5s infinite;
  -moz-animation: arrow-data 1.5s infinite;
  animation: arrow-data 1.5s infinite;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-delay: .1s;
  -moz-animation-delay: .1s;
  animation-delay: .1s;
}
.more-arrow > b:nth-child(2){
  background: url("../../assets/images/more-icon-2.png") center no-repeat;
  background-size: 1.33rem 100%;
  -webkit-animation: arrow-data 1.5s infinite;
  -moz-animation: arrow-data 1.5s infinite;
  animation: arrow-data 1.5s infinite;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-delay: .3s;
  -moz-animation-delay: .3s;
  animation-delay: .3s;
}
.more-arrow > b:nth-child(3){
  background: url("../../assets/images/more-icon-3.png") center no-repeat;
  background-size: 1.33rem 100%;
  -webkit-animation: arrow-data 1.5s infinite;
  -moz-animation: arrow-data 1.5s infinite;
  animation: arrow-data 1.5s infinite;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-delay: .1s;
  -moz-animation-delay: .5s;
  animation-delay: .5s;
}

@keyframes arrow-data
{
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes arrow-data
{
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.list-box{
  display: block;
  width: 100%;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  transition-duration: 0ms;
  -webkit-transition-duration: 0ms;
}
</style>
